<template>
  <div class="flex md:flex-row flex-col vh-fix">
    <div
      id="left"
      class="sidebar"
      >
      <div class="flex md:flex-col lg:flex-row justify-center px-4 pb-10">
        <img
          src="logo-swisscom.svg"
          alt="Swiss Climate Challenge"
          importance="high"
          rel="preload"
          class="lg:mr-3 mb-4 lg:mb-0 max-h-10"
          >
        <img
          src="logo-post-finance.svg"
          alt="Swiss Climate Challenge"
          importance="high"
          rel="preload"
          class="lg:ml-3 max-h-10"
          >
      </div>
    </div>

    <div
      id="right"
      class="main"
      >
      <div
        class="content"
        >
        <!-- Intro -->
        <div class="pt-24 md:pt-0">
          <h3 class="date">
            {{ $t('home.date') }}
          </h3>
          <h1 class="title">
            {{ $t('home.title') }}
          </h1>

          <h3 class="sub-title">
            {{ $t('home.completion', { target: formattedRange }) }}
          </h3>
        </div>

        <!-- Progress bar -->
        <div class="progress-bar-wrapper">
          <div class="shield">
            <img
              src="shield.png"
              alt="Swiss Climate Challenge"
              importance="high"
              rel="preload"
              >
          </div>

          <div class="flex-grow">
            <h3 class="target">
              {{ $t('home.target', { target: formattedRange }) }}
            </h3>

            <div
              class="progress-bar"
              :style="`width: ${bar.width}%`"
              >
              <div
                class="progress"
                :style="`width: ${progressBarPercentage}%;`"
                />
            </div>

            <div class="flex justify-between">
              <span class="flex items-center">
                <div>
                  <cdc-leaf class="leaf" />
                </div>

                <h3 class="formatted-points">
                  {{ $t('home.currentProgress', { progress: formattedPoints }) }}
                </h3>
              </span>

              <h3 class="formatted-range">
                {{ formattedRange }}
              </h3>
            </div>
          </div>
        </div>

        <!-- Footer -->
        <div class="footer">
          <p class="url">
            {{ $t('home.url') }}
          </p>

          <a href="https://www.swissclimatechallenge.ch">
            <img
              src="logo.svg"
              alt="Swiss Climate Challenge"
              importance="high"
              rel="preload"
              >
          </a>
        </div>

        <div class="md:hidden flex md:flex-col lg:flex-row justify-center px-4 py-4">
          <img
            src="logo-swisscom.svg"
            alt="Swiss Climate Challenge"
            importance="high"
            rel="preload"
            class="mr-2 max-h-10"
            >
          <img
            src="logo-post-finance.svg"
            alt="Swiss Climate Challenge"
            importance="high"
            rel="preload"
            class="ml-2 max-h-10"
            >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import anime from 'animejs/lib/anime.min';
import cdcLeaf from '@/components/Leaf.vue';
import StatService from '@/services/StatService';

export default {
  components: {
    cdcLeaf,
  },
  data() {
    return {
      progress: {
        points: 0,
        range: 100000,
      },
      result: null,
      bar: {
        width: 0,
      },
      interval: 60000,
    };
  },
  computed: {
    formattedRange() {
      const { getLanguageWithCountryCode } = this;
      return new Intl.NumberFormat(getLanguageWithCountryCode).format(this.progress.range);
    },
    formattedPoints() {
      const { getLanguageWithCountryCode } = this;
      return new Intl.NumberFormat(getLanguageWithCountryCode).format(this.progress.points);
    },
    progressBarPercentage() {
      return this.clamp((this.progress.points * 100) / this.progress.range, 0, 100);
    },
    getLanguage() {
      return this.$i18n.locale;
    },
    getLanguageWithCountryCode() {
      if (this.getLanguage === 'en') {
        return 'en-US';
      }
      if (this.getLanguage === 'de') {
        return 'de-CH';
      }
      if (this.getLanguage === 'fr') {
        return 'fr-CH';
      }
      if (this.getLanguage === 'it') {
        return 'it-CH';
      }
      return 'en-US';
    },
  },
  async created() {
    await this.fetchData();

    setInterval(async () => {
      await this.fetchData();
    }, this.interval);

    await this.animateBar();
  },
  methods: {
    randomNumber(min, max) {
      const newMin = Math.ceil(min);
      const newMax = Math.floor(max);
      return Math.floor(Math.random() * (newMax - newMin + 1)) + newMin;
    },
    clamp(value, min, max) {
      return Math.min(Math.max(value, min), max);
    },
    animatePoints() {
      this.$nextTick(() => {
        anime({
          targets: this.progress,
          points: this.result?.points || 0,
          // points: this.randomNumber(0, this.progress.range),
          round: 1,
          easing: 'easeOutQuart',
        });
      });
    },
    animateBar() {
      this.$nextTick(() => {
        anime({
          targets: this.bar,
          width: 100,
          round: 1,
          easing: 'easeOutQuart',
        });
      });
    },
    async fetchData() {
      const endDate = process.env.VUE_APP_END_DATE;
      const startDate = process.env.VUE_APP_START_DATE;

      const response = await StatService.getChallengeDayTotalPoints(endDate, startDate);

      this.result = response.result;

      this.$log.info('response', response.result.points, response.updatedAt);
      await this.animatePoints();
    },
  },
};
</script>

<style lang="postcss">
/* Fix browser miscalculation */
.vh-fix {
  height: calc(100vh - var(--vh-offset, 0px));
}

.main {
  @apply bg-primary-darkest md:w-2/3 lg:w-2/3 flex-grow md:p-12
    flex flex-col justify-end md:justify-between md:overflow-auto
    bg-hero md:bg-none bg-cover bg-center;
}

.content {
  @apply bg-opacity-70 md:bg-transparent flex flex-col justify-between md:flex-grow p-6 md:p-0
      bg-gradient-to-b md:bg-none from-transparent via-black to-black;
}

.progress-bar-wrapper {
  @apply p-6 bg-primary-darkest md:bg-transparent rounded-2xl md:px-0 md:pb-12
  flex flex-row items-center;
}

.footer {
  @apply hidden md:flex justify-between items-end;
}

.sidebar {
  @apply md:w-1/3 lg:w-1/3 min-h-64 bg-hero bg-cover bg-center transition-all
    duration-500 ease-out hidden md:flex flex-col justify-end flex-grow;
}

.date {
  @apply text-white md:leading-10 text-base sm:text-2xl lg:text-4xl md:pt-6 md:pb-4;
}

.title {
  @apply text-white font-extrabold pb-4 md:pb-10 text-2xl sm:text-4xl lg:text-6xl;
}

.sub-title {
  @apply text-white md:leading-snug lg:leading-snug text-base sm:text-2xl lg:text-4xl
  pb-4 md:pb-0;
}

.target {
  @apply text-white font-bold text-sm sm:text-xl md:text-4xl lg:text-5xl;
}

.formatted-points {
  @apply text-primary-lightest font-bold text-sm sm:text-xl md:text-4xl lg:text-5xl;
}

.formatted-range {
  @apply hidden md:block text-primary-lightest font-bold
  text-base sm:text-xl md:text-4xl lg:text-5xl;
}

.url {
  @apply hidden md:block text-base md:text-lg text-white sm:pr-8;
}


.progress-bar {
  @apply h-2 md:h-10 rounded-full bg-primary-dark my-2 md:my-5 w-80;
}

.progress {
  @apply min-w-2 md:min-w-10 h-full rounded-full bg-secondary-green ;
}

.leaf {
  @apply mr-2 md:mr-4 w-3 sm:w-4 md:w-10;
}

.shield {
  @apply md:hidden w-12 sm:w-16 mr-4;
}
</style>
