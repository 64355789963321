<template>
  <div>
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 46 46"
      width="100%"
      height="100%"
      class="text-primary-lightest"
      >
      <path
        d="M11.878 7.411C17.988 3.001 27.322-.485 40.82.055c3.827 0 5.578 1.306 5.103
      5.105-.103.824-.206 1.697-.313 2.61-.958 8.13-2.288 19.413-7.296 26.759a21.643 21.643
      0 01-2.843 3.535c-9.367 9.383-26.427 8.195-32.444 7.43C9.24 38.951 15.301 32.899 20.47
      27.737c9.708-9.694 16.268-16.244 14.77-16.994C32.566 6.73 8.803 31.73.346
      41.527c-.722-6.97-1.142-22.282 7.549-30.983a21.83 21.83 0 013.982-3.133z"
        />
    </svg>
  </div>
</template>

<script>
export default {

};
</script>

<style>

</style>
