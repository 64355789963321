<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import vhCheck from 'vh-check';

export default {
  computed: {
    env: () => process.env,
  },
  created() {
    this.$log.info('Created: App');

    // init vhCheck, a fix for Safari VH miscalculation
    vhCheck();

    let currentLang = this.$route.query.userLang || window.navigator.language.slice(0, 2);

    const supportedLangs = ['en', 'de', 'fr', 'it'];

    if (!supportedLangs.includes(currentLang)) {
      currentLang = process.env.VUE_APP_I18N_FALLBACK_LOCALE;
    }

    this.$log.info('currentLang', currentLang);
    this.$i18n.locale = currentLang;
  },
};
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
